<template>
  <EKDialog
    :isEdit="!!yearDto.id"
    ref="dialog"
    :title="yearDto.id ? 'تعديل  سنة' : 'اضافة سنة '"
    @close="resetForm"
    @ok="onSubmit()"
    @delete="deleteYea(yearDto.id)"
  >
    <template slot="body">
      <ValidationObserver ref="observer">
        <EKInputText
          :rules="[{ type: 'required', message: 'اسم السنة مطلوب' }]"
          label="اسم السنة "
          v-model="yearDto.name"
          placeholder="ادخل اسم السنة"
          name=" name"
        />
      </ValidationObserver>
    </template>
  </EKDialog>
</template>
<script>
import { ValidationObserver } from "vee-validate";
import EKDialog from "@Ekcore/components/EK-dialog";
import EKInputText from "@Ekcore/components/EK-forms/EK-input-text";
import { mapState, mapActions } from "vuex";
export default {
  components: {
    ValidationObserver,
    EKDialog,
    EKInputText,
  },
  computed: {
    ...mapState({
      yearDto: (state) => state.settings.yearDto,
      isDialogOpen: (state) => state.settings.isDialogOpen,
    }),
  },
  methods: {
    ...mapActions(["addYears"]),
    open() {
      this.$refs.dialog.open();
    },
    onSubmit() {
      this.$refs.observer.validate().then((success) => {
        if (success) {
          this.addYears(this.yearDto);
          this.$refs.dialog.close();
        }
      });
    },
    resetForm() {
      this.$refs.observer.reset();
      this.$store.commit("Set_Year_Dto");
      this.$store.commit("IS_DIALOG_OPEN", false);
    },
    deleteYea(id) {
      this.$store.dispatch("deleteYear", [id]);
    },
  },
  watch: {
    isDialogOpen(nv) {
      if (nv) {
        this.$refs.dialog.open();
      }
    },
  },
};
</script>
