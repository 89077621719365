<template>
    <div>
        <!--update rate-->
        <div class="p-1 rounded shadow">
            <ValidationObserver ref="form">
                <div>
                    <h4>النسب</h4>
                    <b-row>
                        <b-col cols="12" md="6" lg="6">
                            <ek-input-text
                                :rules="[
                                    {
                                        type: 'required',
                                        message: 'نسبة نقطة البيع مطلوبة ',
                                    },
                                ]"
                                name="sellPointRate"
                                placeholder="أدخل نسبة نقطة البيع"
                                label="نسبة نقطة البيع "
                                v-model="dashDefaultValues.sellPointRate"
                            >
                            </ek-input-text>
                        </b-col>
                        <b-col cols="12" md="6" lg="6">
                            <ek-input-text
                                :rules="[
                                    {
                                        type: 'required',
                                        message: '  سعر الكود مطلوب ',
                                    },
                                ]"
                                name="sellPointRate"
                                placeholder="أدخل   مدة الكود"
                                label="   المدة الافتراضية لإنتهاء الرمز (بالايام) "
                                v-model="dashDefaultValues.codePeriod"
                            >
                            </ek-input-text>
                        </b-col>
                    </b-row>
                </div>
            </ValidationObserver>
            <div class="w-100 d-flex justify-content-between mt-2">
                <div>
                    <b-button variant="primary" @click="submit">تعديل</b-button>
                </div>
            </div>
        </div>
        <div class="mt-3">
            <b-row>
                <!--tabel year-->
                <b-col cols="12" md="6" lg="6">
                    <ek-table
                        :items="settingDto.years"
                        :columns="yearCols"
                        selectedLabel="id"
                        title="السنوات"
                        isPlus
                        @plus="setYearDialogForm()"
                        @details="setYearDialogForm($event)"
                        @delete-selected="deleteYear"
                    >
                    </ek-table>
                </b-col>

                <!-- tabel semester-->
                <b-col cols="12" md="6" lg="6">
                    <ek-table
                        :items="settingDto.semesters"
                        :columns="semesterCols"
                        selectedLabel="id"
                        title="الفصول"
                        isPlus
                        @plus="setSemesterDialogForm()"
                        @details="setSemesterDialogForm($event)"
                        @delete-selected="deleteSemester"
                    >
                    </ek-table>
                </b-col>
            </b-row>

            <!--form add & update teacher-->
            <div class="d-flex justify-content-end mb-1">
                <ek-dialog
                    :isEdit="!!teacherDto.id"
                    :title="teacherDto.id ? 'تعديل  استاذ' : 'اضافة استاذ '"
                    @delete="deleteT(teacherDto.id)"
                    btnText=" استاذ جديد"
                    ref="dialogTeacher"
                    placeholder="ابحث عن استاذ محدد"
                    @ok="submitForm"
                    @close="reset"
                >
                    <template #body>
                        <validationObserver ref="teacherForm">
                            <ek-input-text
                                name="name "
                                placeholder="ادخل اسم الاستاذ"
                                label="الاسم الكامل "
                                :rules="[
                                    {
                                        type: 'required',
                                        message: 'اسم الاستاذ مطلوب',
                                    },
                                ]"
                                v-model="teacherDto.name"
                            ></ek-input-text>

                            <ek-input-text
                                type="number"
                                name="phone "
                                placeholder="ادخل رقم الموبايل "
                                label="رقم الموبايل "
                                :rules="[
                                    {
                                        type: 'required',
                                        message: 'رقم الموبايل مطلوب',
                                    },
                                ]"
                                v-model="teacherDto.phoneNumber"
                            ></ek-input-text>

                            <ek-input-text
                                name="password "
                                placeholder="ادخل كلمة السر  "
                                label="كلمة السر"
                                v-model="teacherDto.password"
                            ></ek-input-text>

                            
                    <label   class="mt-2"
                        >صورة</label
                    >
                    <ek-input-image
                    v-if="
                            !teacherDto.imageUrl 
                        "
                        name="img"
                        :image="
                            teacherDto.imageUrl
                                ? $store.getters['app/domainHost'] +
                                  '/' +
                                  teacherDto.imageUrl
                                : ''
                        "
                        accept="image/png, image/jpeg"
                        @input="uploadSubFile($event)"
                        ref="fileInput"
                    >
                        <h5>اسحب الملف او انقر للتحميل</h5>
                    </ek-input-image>

                    <b-dropdown-item
                        v-if="  teacherDto.imageUrl"
                        href="#"
                        ref="myUploader"
                        @click="fileWindowApi(onUpload)"
                    >
                        <unicon
                            name="edit-alt"
                            fill="#2EC4B6"
                            style="width: 25px"
                        ></unicon>
                        <span>تعديل الصورة</span>
                    </b-dropdown-item>
                    <img
                        v-if="  teacherDto.imageUrl"
                        class="h-100 w-100 rounded"
                        style="object-fit: cover"
                        :src="
                            $store.getters['app/domainHost'] +
                            '/' +
                            teacherDto.imageUrl
                        "
                        alt=""
                    />
                        </validationObserver>
                    </template>
                </ek-dialog>
            </div>

            <!--tabel teacher-->
            <b-row>
                <b-col cols="12" md="12" lg="12">
                    <ek-table
                        :items="settingDto.teachers"
                        @delete-selected="deleteTeacher"
                        :columns="teacherCols"
                        @details="teacherDetails"
                    >
                        <template
                            slot="items.dateCreated"
                            slot-scope="{ value }"
                        >
                            <span>
                                {{ new Date(value).toLocaleDateString() }}</span
                            >
                        </template>
                    </ek-table>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" md="6" lg="6">
                    <ek-table
                        :items="tagsList.tags"
                        @delete-selected="deleteTag"
                        :columns="colorCol"
                        @details="TagDetails"
                        selectedLabel="id"
                        title="الوسوم"
                        isPlus
                        @plus="setTagDialogForm()"
                    >
                        <template slot="items.color" slot-scope="{ value }">
                            <div
                                :style="`background-color: ${
                                    value.startsWith('#') ? value : '#' + value
                                } !important; height: 35px !important; width: 90px !important;`"
                                class="devSpan"
                            >
                                <!-- <input type="color" :value="value" /> -->
                            </div>
                        </template>
                    </ek-table>
                </b-col>
            </b-row>
            <year ref="yearDialog" />
            <tags ref="TagDialog" />
            <semester ref="semesterDialog" />
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import year from "@admin/settings/components/AddYear";
import tags from "@admin/settings/components/AddTags";
import semester from "@admin/settings/components/AddSemester";
import { fileWindowApi } from "@core/utils/uploader";

export default {
    components: {
        year,
        semester,
        tags,
    },

    computed: {
        ...mapState({
            settingDto: ({ settings }) => settings.settingDto,
            dashDefaultValues: ({ settings }) => settings.dashDefaultValues,
            teacherDto: ({ settings }) => settings.teacherDto,
            isDialogOpen: (state) => state.settings.isDialogOpen,
            isDialog: (state) => state.settings.isDialog,
            teacherCols: ({ settings }) => settings.teacherCols,
            semesterList: ({ settings }) => settings.semesterList,
            tagsList: ({ settings }) => settings.tagsList,
            semesterCols: ({ settings }) => settings.semesterCols,
            yearList: ({ settings }) => settings.yearList,
            yearCols: ({ settings }) => settings.yearCols,
            colorCol: ({ settings }) => settings.colorCol,
        }),
    },
    methods: {
        ...mapActions([
            "getRate",
            "getSettings",
            "getSettingYear",
            "getSettingTeacher",
            "getSettingSemester",
            "updateRate",
            "addTeacher",
            "addFile",
            "deleteYear",
            "deleteTeacher",
            "deleteTage",
            "getTags",
            "addTags",
            "deleteSemester",
            "getGetUnRead",
        ]),

        //update value rate
        submit() {
            this.$refs["form"].validate().then((suc) => {
                if (suc) {
                    this.updateRate({
                        sellPointRate: this.dashDefaultValues.sellPointRate,
                        codePeriod: this.dashDefaultValues.codePeriod,
                    });
                }
            });
        },

        //add & update teacher
        submitForm() {
            this.$refs["teacherForm"].validate().then((suc) => {
                if (suc) {
                    if (this.teacherDto.password == null) {
                        let password = "";
                        this.teacherDto.password = password;
                    }
                    this.addTeacher(this.teacherDto);
                    this.$refs.teacherForm.reset();
                    this.$store.commit("Reset_Teacher_Dto");
                    this.$refs.dialogTeacher.close();
                }
            });
        },

        //open dialog teacher
        teacherDetails({ row }) {
            this.$store.commit("IS_DIALOG", true);
            this.$store.commit("SET_TEACHER_DTO", row);
        },
        TagDetails({ row }) {
            this.$refs.TagDialog.open();

            this.$store.commit("SET_TAG_DTO", row);
        },

        //open dialog years
        setYearDialogForm(item) {
            if (!item) {
                this.$store.commit("Set_Year_Dto");
            } else {
                this.$store.commit("Set_Year_Dto", item.row);
            }
            this.$refs.yearDialog.open();
        },
        setTagDialogForm(item) {
            if (!item) {
                this.$store.commit("SET_TAG_DTO");
            } else {
                this.$store.commit("SET_TAG_DTO", item.row);
            }
            this.$refs.TagDialog.open();
        },
        fileWindowApi,
        onUpload(event) {
            if (event && event.file) {
                this.addFile({ file: event.file, folderName: "Profile" }).then(
                    (res) => {
                        this.teacherDto.imageUrl = res.data;
                    }
                );
            }
        },
        uploadSubFile(event) {
            if (event && event.file) {
                this.addFile({ file: event.file, folderName: "Profile" }).then(
                    (res) => {
                        this.teacherDto.imageUrl = res.data;
                    }
                );
            }
        },
        //open dialog semesters
        setSemesterDialogForm(item) {
            if (!item) {
                this.$store.commit("Set_Semester_Dto");
            } else {
                this.$store.commit("Set_Semester_Dto", item.row);
            }
            this.$refs.semesterDialog.open();
        },

        //delete teacher
        deleteT(id) {
            this.$store.dispatch("deleteTeacher", [id]);
        },
        deleteTag(id) {
            this.$store.dispatch("deleteTage", id).then(() => {
                this.getTags();
            });
            this.getTags();
        },

        //reset dialog teacher
        reset() {
            this.$refs.teacherForm.reset();
            this.$store.commit("SET_TEACHER_DTO");
            this.$store.commit("IS_DIALOG", false);
            this.$refs.dialogTeacher.close();
        },
    },
    created() {
        this.getSettings();
        this.getRate();
        this.getSettingTeacher();
        this.getSettingYear();
        this.getSettingSemester();
        this.getGetUnRead();
        this.getTags();
    },
    watch: {
        isDialog(nv) {
            if (nv) {
                this.$refs.dialogTeacher.open();
            }
        },
    },
};
</script>
<style scoped>
input[type="color"] {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background: none;
    border: 0;
    cursor: pointer;
    padding: 0;
}
.devSpan {
    height: 50px !important;
    padding: 0 !important;
    width: 100px !important;
    border-radius: 40px !important;
}
</style>
