<template>
    <EKDialog
        :isEdit="!!tagDto.id"
        ref="dialog"
        :title="tagDto.id ? 'تعديل  وسم' : 'اضافة وسم '"
        @close="resetForm"
        @ok="onSubmit()"
        @delete="deleteYea(tagDto.id)"
    >
        <template slot="body">
            <ValidationObserver ref="tag">
                <EKInputText
                    :rules="[{ type: 'required', message: 'اسم الوسم مطلوب' }]"
                    label="اسم الوسم "
                    v-model="tagDto.name"
                    placeholder="ادخل اسم الوسم"
                    name=" name"
                />
                <EKInputText
                    :rules="[{ type: 'required', message: 'لون الوسم مطلوب' }]"
                    label="لون الوسم "
                    v-model="tagDto.color"
                    placeholder="ادخل لون الوسم"
                    name=" color"
                    type="color"
                />
            </ValidationObserver>
        </template>
    </EKDialog>
</template>
<script>
import { ValidationObserver } from "vee-validate";
import EKDialog from "@Ekcore/components/EK-dialog";
import EKInputText from "@Ekcore/components/EK-forms/EK-input-text";
import { mapState, mapActions } from "vuex";
export default {
    components: {
        ValidationObserver,
        EKDialog,
        EKInputText,
    },
    computed: {
        ...mapState({
            tagDto: (state) => state.settings.tagDto,
            isDialogOpen: (state) => state.settings.isDialogOpen,
        }),
    },
    methods: {
        ...mapActions(["addTags", "getTags"]),
        open() {
            this.$refs.dialog.open();
        },
        onSubmit() {
            this.$refs.tag.validate().then((success) => {
                if (success) {
                    this.addTags({
                        ...this.tagDto,
                    }).then(() => {
                        this.$refs.dialog.close();
                        this.getTags();
                    });
                }
            });
        },
        resetForm() {
            this.$refs.tag.reset();
            this.$store.commit("SET_TAG_DTO");
            this.$store.commit("IS_DIALOG_OPEN", false);
        },
        deleteYea(id) {
            this.$store.dispatch("deleteTage", [id]);
        },
    },
    watch: {
        isDialogOpen(nv) {
            if (nv) {
                this.$refs.dialog.open();
            }
        },
    },
};
</script>
